'use client';

import { trpc, trpcClient } from '@/utils/trpc';
import { Toaster } from '@streetferret/ui-web/toaster';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ThemeProvider } from 'next-themes';
import { PreferencesProvider, type Unit } from '@streetferret/providers';
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export interface ProvidersProps {
  children: React.ReactNode | React.ReactNode[];
  unit: Unit;
  defaultTheme?: 'dark' | 'light' | 'system';
}
export function Providers({
  children,
  unit,
  defaultTheme = 'system',
}: ProvidersProps) {
  return (
    <ThemeProvider defaultTheme={defaultTheme}>
      <PreferencesProvider unit={unit}>
        <trpc.Provider queryClient={queryClient} client={trpcClient}>
          <QueryClientProvider client={queryClient}>
            {children}
            <Toaster />
          </QueryClientProvider>
        </trpc.Provider>
      </PreferencesProvider>
    </ThemeProvider>
  );
}

BigInt.prototype['toJSON'] = function () {
  // const int = Number.parseInt(this.toString());
  return this.toString();
};
