'use client';
import { Button } from '@streetferret/ui-web/button';
import { FaTrophy } from 'react-icons/fa';
import Confetti from 'react-dom-confetti';
import { useState } from 'react';
import { cn } from '@streetferret/utils';

type ConfettiConfig = React.ComponentProps<typeof Confetti>['config'];
const config: ConfettiConfig = {
  angle: 90,
  spread: 960,
  startVelocity: 40,
  elementCount: 70,
  dragFriction: 0.22,
  duration: 5000,
  stagger: 3,
  width: '10px',
  height: '10px',
  //@ts-expect-error - types are wrong
  perspective: '500px',
  colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
};
export function ConfettiButton({
  children,
  onBackground,
}: {
  children?: React.ReactNode;
  onBackground?: boolean;
}) {
  const [isConfetti, setIsConfetti] = useState(false);
  function triggleConfetti() {
    if (isConfetti) return;
    setIsConfetti(true);
    setTimeout(() => {
      setIsConfetti(false);
    }, 5000);
  }
  return (
    <Button
      variant={'ghost'}
      onClick={triggleConfetti}
      className="z-20 relative stroke-1 !p-1 !h-5 group">
      {children}
      <FaTrophy
        className={cn(
          'fill-black group-hover:fill-white ml-1',
          onBackground && 'fill-foreground',
        )}
      />
      <Confetti active={isConfetti} config={config} />
    </Button>
  );
}
