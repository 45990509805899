import { Button } from '@streetferret/ui-web/button';
import Link from 'next/link';

export function DonateButton() {
  return (
    <Button
      asChild
      variant="secondary"
      className="!text-black bg-yellow-400 hover:bg-yellow-200">
      <Link href={'/donate'}>Donate</Link>
    </Button>
  );
}
